<template>
  <div id="Meme">
    <div class="meme-section">
      <div class="top">
        <img class="absolute" src="/img/threespace/meme/top-background.png"/>
        <div class="title">
          The Third Space
        </div>
        <div class="subtitle">
          Build the Web3 World with us
        </div>
        <div class="img-container">
          <img src="/img/threespace/meme/top-middle-image.png" />
        </div>
      </div>
      <div class="middle">
        <div class="img-container">
          <img src="/img/threespace/meme/middle-image.png" />
        </div>
      </div>
      <div class="bottom">
        <img class="absolute" src="/img/threespace/meme/bottom-background.png"/>
        <div class="title">
          {{$t("meme.title")}}
        </div>
        <div class="subtitle-section">
          <div>
            <div class="subtitle">{{$t("meme.description1")}}</div>
            <div class="subtitle">{{$t("meme.description2")}}</div>
            <div class="subtitle">{{$t("meme.description3")}}</div>
          </div>
          <div>
            <div class="subtitle">{{$t("meme.description4")}}</div>
              <div class="subtitle">{{$t("meme.description5")}}</div>
          </div>
        </div>
        <div class="btn-wrap">
          <button>Coming Soon</button>
        </div>
        <div class="mission-wraps">
          <template v-for="item in 6">
            <div class="mission" :key="item">
              <div>{{$t(`meme.mission${item}`)}}</div>
              <img src="/img/threespace/meme/mission.png"/>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {  },
  data() {
    return {};
  },
  methods: {}
};
</script>
